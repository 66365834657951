var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Grid, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import { alpha } from "@mui/material";
import { ClientConfigContext } from "../../../context/client-config";
import { useCssContext } from "../../../context/css-context";
import CustomerSupport from "../../customerSupport/CustomerSupport";
var SupportTypography = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return {
        "& a": __assign({ color: theme.palette.primary.main, textDecorationColor: alpha(theme.palette.primary.main, 0.4) }, theme.typography.body2),
        "& a:hover": {
            textDecorationColor: alpha(theme.palette.primary.main, 1.0)
        }
    };
});
var Footer = function (_a) {
    var _b = _a.appVersion, appVersion = _b === void 0 ? "" : _b, _c = _a.privacyLinkTarget, privacyLinkTarget = _c === void 0 ? "_blank" : _c, privacyLinkText = _a.privacyLinkText, privacyLinkUrl = _a.privacyLinkUrl, _d = _a.termsLinkTarget, termsLinkTarget = _d === void 0 ? "_blank" : _d, termsLinkText = _a.termsLinkText, termsLinkUrl = _a.termsLinkUrl, _e = _a.confirmForgotUrl, confirmForgotUrl = _e === void 0 ? "" : _e, _f = _a.isLanding, isLanding = _f === void 0 ? false : _f;
    var year = new Date().getFullYear();
    var state = useContext(ClientConfigContext)[0];
    var classes = useCssContext().classes;
    return (_jsxs(Grid, __assign({ item: true, direction: "column", sx: { textAlign: 'center' } }, { children: [!state.landing.useCustomerSupportComponent && _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(SupportTypography, { className: classes.footerText, variant: "body2", dangerouslySetInnerHTML: { __html: state.landing.supportHtml } }) })), state.landing.useCustomerSupportComponent && _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(CustomerSupport, {}) })), isLanding &&
                _jsx(Grid, __assign({ item: true, justifyContent: "center", marginTop: 2, marginBottom: 2, className: classes.hideFooterAccessCodeLink }, { children: _jsxs(Grid, __assign({ item: true, xs: true }, { children: [_jsx(Typography, __assign({ variant: "body2", align: "center", display: "block" }, { children: "Already have an access code?" })), _jsx(Link, __assign({ component: RouterLink, to: confirmForgotUrl, variant: "body2", id: "returnToConfirmForgotPasswordLink" }, { children: "You can enter it here." }))] })) })), _jsxs(Grid, __assign({ item: true, xs: true, className: classes.hideCopyright }, { children: [_jsxs(Typography, __assign({ variant: "body2", align: "center" }, { children: [_jsx("span", { children: "For support, call 877-563-0012 or email " }), _jsx(Link, __assign({ href: 'mailto:customer_support@spireon.com', variant: "body2", rel: "noopener" }, { children: "customer_support@spireon.com" }))] })), _jsxs(Typography, __assign({ variant: "body2", align: "center" }, { children: ["\u00A9 ", year, " Spireon, Inc. All rights reserved."] }))] })), _jsxs(Grid, __assign({ container: true, item: true, justifyContent: "center", className: classes.privacyStmt }, { children: [_jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(Link, __assign({ className: classes.footerText, href: privacyLinkUrl, target: privacyLinkTarget, variant: "body2", rel: "noopener" }, { children: privacyLinkText })) })), _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(Link, __assign({ className: classes.footerText, href: termsLinkUrl, target: termsLinkTarget, variant: "body2", rel: "noopener" }, { children: termsLinkText })) }))] }))] })));
};
export default Footer;
