import red from '@mui/material/colors/red';

const theme = {
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    margin: 0,
                    padding: 0,
                },
            },
        },
    },
    palette: {
        primary: {
            light: '#ff7b48',
            main: '#243A7F',
            dark: '#a2b4c3',
            contrastText: '#fff',
        },
        secondary: {
            light: '#5c6b73',
            main: '#324048',
            dark: '#0c1a21',
            contrastText: '#fff',
        },
        error: {
            main: red[400],
        },
        background: {
            default: '#fff',
        }
    },
};

export default theme;
