import red from '@mui/material/colors/red';
import { alpha } from '@mui/system';

const theme = {
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    margin: 0,
                    padding: 0,
                },
            },
        },
    },
    palette: {
        primary: {
            light: '#b3282d',
            main: '#b3282d',
            dark: '#b3282d',
            contrastText: '#fff',
        },
        secondary: {
            light: '#5c6b73',
            main: '#324048',
            dark: '#0c1a21',
            contrastText: '#fff',
        },
        error: {
            main: red[400],
        },
        background: {
            default: '#fff',
        }
    },
};

export const styles = (theme) => {
    return ({
        loginFormGrid: {
            paddingRight:'44px'
        },
        loginForm: {
            width: 310
        },
        appLogo: {
            width: 310
        },
        forgotPassword: {
            display: 'flex',
            justifyContent: 'end',
            padding: '5px 0px 16px 24px',
            fontSize: 16,
            fontWeight: 600,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: '-.6px !important',

            '& a': {
                textDecoration: 'none',
            }
        },
        periscope: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '30px !important',
            marginLeft: '5px !important'
        },
        periscopeGoogle: {
            marginLeft: '10px !important',
        },
        singInButton: {
            height: 48,
            borderRadius: 4,
            fontSize: '24px !important',
            border: 'none',
            fontFamily: 'Roboto,sans-serif',
            textTransform: 'capitalize !important',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            padding: '6px 59px 8px',
            letterSpacing: '-0.6px !important'
        },
        alertInput: {
            backgroundColor: '#fff !important',
            padding: '6px 0px !important',

            '& div': {
                marginRight: 4,
                color: '#000',
                letterSpacing: '-.5px',
                fontFamily: 'Roboto Medium',
                opacity: .67,
                fontWeight: 600,
                fontStyle: 'normal',
                fontStretch: 'normal',
            }
        },
        supportHtml:{
            display: 'Block',
            fontSize: '0.875rem',
            fontFamily:'"Roboto","Helvetica","Arial",sans-serif',
            fontWeight:'400',
            lineHeight:'1.43',
            letterSpacing:'0.01071em',
            '& a':{
                color: theme.palette.primary.main,
                textDecorationColor: alpha(theme.palette.primary.main, 0.4),

                '&:hover': {
                    textDecorationColor: alpha(theme.palette.primary.main, 1.0)
                }
            },
        },
        hideCopyright: {
            display: 'none !important',
        },
        privacyStmt:{
            display: 'none !important',
        },
        showMarketingImage: {
            top: '0',
            left: '0',
            position: 'relative',
            float: 'left',
            overflow: 'hidden',
            height: '100vh',
            width: '100%'
        }
    });
}

export default theme;
