export var AnalyticsEvent;
(function (AnalyticsEvent) {
    AnalyticsEvent["APP_TYPE"] = "auth_service";
    AnalyticsEvent["SCREEN_LANDING"] = "SCREEN_LANDING";
    AnalyticsEvent["SCREEN_USER_MIGRATION"] = "SCREEN_USER_MIGRATION";
    AnalyticsEvent["SCREEN_CONFIRM_EMAIL"] = "SCREEN_CONFIRM_EMAIL";
    AnalyticsEvent["SCREEN_FORGOT_PASSWORD"] = "SCREEN_FORGOT_PASSWORD";
    AnalyticsEvent["SCREEN_CONFIRM_FORGOT_PASSWORD"] = "SCREEN_CONFIRM_FORGOT_PASSWORD";
    AnalyticsEvent["SCREEN_ACCEPT_FORGOT_PASSWORD_INVITATION"] = "SCREEN_ACCEPT_FORGOT_PASSWORD_INVITATION";
    AnalyticsEvent["SCREEN_CHANGE_PASSWORD"] = "SCREEN_CHANGE_PASSWORD";
    AnalyticsEvent["SCREEN_CUSTOM_MFA"] = "SCREEN_CUSTOM_MFA";
    AnalyticsEvent["SCREEN_SET_FIRST_PASSWORD"] = "SCREEN_SET_FIRST_PASSWORD";
    AnalyticsEvent["SCREEN_SSO_VERIFICATION"] = "SCREEN_SSO_VERIFICATION";
    AnalyticsEvent["CLICK_LOGIN"] = "CLICK_LOGIN";
    AnalyticsEvent["CLICK_USER_MIGRATION"] = "CLICK_USER_MIGRATION";
    AnalyticsEvent["CLICK_CONFIRM_EMAIL"] = "CLICK_CONFIRM_EMAIL";
    AnalyticsEvent["CLICK_FORGOT_PASSWORD"] = "CLICK_FORGOT_PASSWORD";
    AnalyticsEvent["CLICK_CONFIRM_FORGOT_PASSWORD"] = "CLICK_CONFIRM_FORGOT_PASSWORD";
    AnalyticsEvent["CLICK_ACCEPT_FORGOT_PASSWORD_INVITATION"] = "CLICK_ACCEPT_FORGOT_PASSWORD_INVITATION";
    AnalyticsEvent["CLICK_CHANGE_PASSWORD"] = "CLICK_CHANGE_PASSWORD";
    AnalyticsEvent["CLICK_CUSTOM_MFA"] = "CLICK_CUSTOM_MFA";
})(AnalyticsEvent || (AnalyticsEvent = {}));
