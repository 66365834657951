var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import RememberMeCheckBoxChange from "./RememberMeCheckBoxChange";
import Cookies from "js-cookie";
import { analyticsTrack, buildPropsWithStandardData } from "../../analytics";
import { AnalyticsEvent } from "../../analytics/analyticsEvents";
import { Alert, Card, CardContent, Grid, InputAdornment, Link, Typography } from "@mui/material";
import { encryptAndPost } from "../../services/encryptedApiService";
import { buildSuccessUrl } from "../../services/successUrlService";
import { CommonLayout } from "../common/layout";
import ControlledTextField from "../common/controlledTextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { CommonSubmitButton } from "../common/submitButton";
import { styled } from "@mui/material/styles";
import { ClientConfigContext } from "../../context/client-config";
import { useCssContext } from "../../context/css-context";
import { clientIdUrl } from "../common/clientIdUrl";
import appStore from "../common/icon/apple_appstore.png";
import googlePlayStore from "../common/icon/google-play-store.png";
var BannerCard = styled(Card)({
    background: '#f6f6f6',
    color: '#ff0000',
    margin: '5px',
    textAlign: 'center',
    wordWrap: 'break-word',
    "& p": {
        margin: 0
    }
});
var SignIn = function () {
    var navigate = useNavigate();
    var state = useContext(ClientConfigContext)[0];
    var classes = useCssContext().classes;
    var searchParams = useSearchParams()[0];
    var _a = useState(false), isError = _a[0], setIsError = _a[1];
    var _b = useState(false), isLoggingIn = _b[0], setIsLoggingIn = _b[1];
    var _c = useState(''), errorCodeMessage = _c[0], setErrorCodeMessage = _c[1];
    var _d = useState(''), loginErrorMessage = _d[0], setLoginErrorMessage = _d[1];
    var REMEMBER_ME = 'REMEMBER_ME';
    var REMEMBER_ME_EXPIRY = 365;
    var getUserNameFromCookies = function () {
        var userName = '';
        var rememberMeData = JSON.parse(Cookies.get(REMEMBER_ME) || '{}');
        if (Object.keys(rememberMeData).length > 0) {
            if (rememberMeData.remember) {
                userName = rememberMeData.username;
            }
        }
        return userName;
    };
    var _e = useState(getUserNameFromCookies() ? true : false), remember = _e[0], setRemember = _e[1];
    var _f = useForm({
        defaultValues: {
            username: getUserNameFromCookies(),
            password: '',
        }
    }), control = _f.control, errors = _f.formState.errors, handleSubmit = _f.handleSubmit;
    var _g = React.useState({
        showPassword: false,
    }), values = _g[0], setValues = _g[1];
    var errorCodeDetails = {
        ACCESS_DENIED: 'User does not have permission to access this site.',
        LOGIN_ERROR: 'Sign-in error occurred.',
        FLEET_ACCOUNT_SUSPENDED_LOGIN_ERROR: 'Your account has been suspended.',
        FLEET_UNABLE_TO_LOGIN: 'Unable to log in with provided credentials.',
        FLEET_SESSION_EXPIRED: 'Your session has expired.',
    };
    if (state.landing.authClientErrorCodeCheck && state.landing.alertPosition === "center") {
        var errorCode = searchParams.get('authErrorCode') || searchParams.get('atiAuthErrorCode');
        if (!isError && errorCode) {
            setIsError(true);
            setErrorCodeMessage(errorCodeDetails[errorCode]);
        }
    }
    var handleClickShowPassword = function () {
        setValues(__assign(__assign({}, values), { showPassword: !values.showPassword }));
    };
    var handleMouseDownPassword = function (event) {
        event.preventDefault();
    };
    var _h = useState(0), unsuccessfulLoginAttempts = _h[0], setUnsuccessfulLoginAttempts = _h[1];
    var forgotPasswordURL = clientIdUrl('/forgot', state, searchParams);
    ;
    var trackLogin = function (extraInfo) {
        analyticsTrack(AnalyticsEvent.CLICK_LOGIN, state === null || state === void 0 ? void 0 : state.analytics, buildPropsWithStandardData(__assign(__assign({}, extraInfo), { viewPage: 'Landing', pageType: 'common' })));
    };
    var forgotPasswordLink = function (props) {
        if (props === void 0) { props = {}; }
        return (_jsx(Link, __assign({ className: classes.forgotPasswordMakai, component: RouterLink, to: forgotPasswordURL, id: "goToForgotPasswordLink" }, props, { children: state.landing.forgotPasswordText || "Forgot password or username?" })));
    };
    var checkUnsuccessfulLoginAttempts = function (unsuccessfulLoginAttempts) {
        if (unsuccessfulLoginAttempts < state.landing.maxUnsuccessfulLoginAttempts) {
            setUnsuccessfulLoginAttempts(unsuccessfulLoginAttempts + 1);
        }
        if (unsuccessfulLoginAttempts == state.landing.maxUnsuccessfulLoginAttempts) {
            setUnsuccessfulLoginAttempts(0);
            navigate(forgotPasswordURL);
        }
    };
    var handleCheckBox = function (isChecked) {
        setRemember(isChecked);
    };
    var handleRememberMe = function (username, remember) { return Cookies.set(REMEMBER_ME, JSON.stringify({ username: username, remember: remember }), { expires: REMEMBER_ME_EXPIRY }); };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var trackReqInfo, loginResult, loginResultJson, e_1, challengeState, challengeState, challengeState;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsError(false);
                    setIsLoggingIn(true);
                    trackReqInfo = { username: data.username };
                    trackLogin(__assign({}, trackReqInfo));
                    if (remember) {
                        handleRememberMe(trackReqInfo.username, remember);
                    }
                    else {
                        Cookies.remove(REMEMBER_ME);
                    }
                    return [4 /*yield*/, encryptAndPost(clientIdUrl('/rest/loginRequest', state, searchParams), data)];
                case 1:
                    loginResult = _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, loginResult.json()];
                case 3:
                    loginResultJson = _a.sent();
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _a.sent();
                    console.error("Failed to parse loginResult as JSON, ".concat(e_1));
                    loginResultJson = {};
                    return [3 /*break*/, 5];
                case 5:
                    if (!loginResult.ok || (loginResultJson.challenge === null && !["ACCOUNT_USER_SCOPE", "USER_SCOPE"].includes(loginResultJson.authResult.scope))) {
                        setIsError(true);
                        setIsLoggingIn(false);
                        setLoginErrorMessage('Invalid credentials.');
                        trackLogin(__assign(__assign({}, trackReqInfo), { result: 'FAILURE' }));
                        if (state.landing.loginAttemptsCheck) {
                            checkUnsuccessfulLoginAttempts(unsuccessfulLoginAttempts);
                        }
                    }
                    else if (loginResultJson.challenge === "MIGRATION") {
                        challengeState = { challengeContext: "", email: "", username: data.username };
                        if (loginResultJson && loginResultJson.challengeContext) {
                            challengeState.challengeContext = loginResultJson.challengeContext;
                        }
                        if (loginResultJson && loginResultJson.challengeData) {
                            challengeState.email = loginResultJson.challengeData.email;
                        }
                        trackLogin(__assign(__assign({}, trackReqInfo), { email: challengeState.email, result: 'MIGRATION' }));
                        navigate("/userMigration?clientId=".concat(searchParams.get('clientId')), { state: challengeState });
                    }
                    else if (loginResultJson.challenge === "EMAIL_VERIFICATION") {
                        challengeState = { challengeContext: "", email: "", token: "", username: data.username };
                        if (loginResultJson && loginResultJson.challengeContext) {
                            challengeState.challengeContext = loginResultJson.challengeContext;
                        }
                        if (loginResultJson && loginResultJson.challengeData) {
                            challengeState.email = loginResultJson.challengeData.email;
                        }
                        if (loginResultJson && loginResultJson.authResult) {
                            challengeState.token = loginResultJson.authResult.token;
                        }
                        trackLogin(__assign(__assign({}, trackReqInfo), { email: challengeState.email, result: 'EMAIL_VERIFICATION' }));
                        navigate(clientIdUrl('/confirmEmail', state, searchParams), { state: challengeState });
                    }
                    else if (loginResultJson.challenge === "CUSTOM_MFA") {
                        challengeState = { challengeContext: "", email: "" };
                        if (loginResultJson && loginResultJson.challengeContext) {
                            challengeState.challengeContext = loginResultJson.challengeContext;
                        }
                        if (loginResultJson && loginResultJson.challengeData) {
                            challengeState.email = loginResultJson.challengeData.channelAddress;
                        }
                        navigate(clientIdUrl('/customMfa', state, searchParams), { state: challengeState });
                    }
                    else if (state.landing.successAction === "REDIRECT") {
                        trackLogin(__assign(__assign({}, trackReqInfo), { result: 'SUCCESS' }));
                        window.location.href = buildSuccessUrl(state.landing.successUrlContext, loginResultJson.authResult.token);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(CommonLayout, __assign({ isLanding: state.landing.pageRef == "MakaiLanding" ? false : true, classes: { loginFormGrid: classes.loginFormGrid, appLogo: classes.appLogo, showMarketingImage: classes.showMarketingImage } }, { children: [state.signInTitle &&
                _jsxs(_Fragment, { children: [_jsx(Grid, __assign({ item: true, sx: { textAlign: "center" } }, { children: _jsx(Typography, __assign({ className: classes.titleText, variant: 'h5' }, { children: "Sign In" })) })), _jsx(Grid, __assign({ item: true, pb: 2, pt: 2, sx: { textAlign: "center" } }, { children: _jsx(Typography, __assign({ className: classes.subtitleText, variant: 'body2' }, { children: "With your username, which might be the email or mobile number you registered with." })) }))] }), isError && state.landing.alertPosition !== "center" &&
                _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(Alert, __assign({ className: classes.alertText, severity: "error" }, { children: "Invalid username or password." })) })), searchParams.get("successMessage") &&
                _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(Alert, __assign({ severity: "success" }, { children: searchParams.get("successMessage") })) })), _jsxs(Grid, __assign({ md: state.splitScreenLayout && 6, sx: { display: "flex", alignItems: "center", flexDirection: "column" } }, { children: [_jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit), className: "".concat(classes.loginForm) }, { children: [_jsx(ControlledTextField, { autoComplete: "username", autoFocus: true, control: control, isError: !!errors.username, helperText: errors.username ? errors.username.message : "", label: "Username", name: "username", rules: { required: _jsx(Typography, __assign({ className: classes.rulesStyleProps, variant: "caption" }, { children: "Required" })) }, variant: state.textfieldVariant ? state.textfieldVariant : 'standard' }), _jsx(ControlledTextField, { autoComplete: "password", control: control, isError: !!errors.password, helperText: errors.password ? errors.password.message : "", label: "Password", name: "password", rules: { required: _jsx(Typography, __assign({ className: classes.rulesStyleProps, variant: "caption" }, { children: "Required" })) }, type: values.showPassword ? "text" : "password", endAdornmentFlag: state.landing.endAdornmentFlagFalse ? false : true, variant: state.textfieldVariant ? state.textfieldVariant : 'standard', endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(IconButton, __assign({ onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword }, { children: values.showPassword ? _jsx(Visibility, { className: classes.eyeIconStyle }) : _jsx(VisibilityOff, { className: classes.eyeIconStyle }) })) })) }), state.landing.forgetPasswordPosition === "center" && state.landing.showRememberMe &&
                                _jsxs(Grid, __assign({ item: true, xs: true, display: "flex" }, { children: [_jsx(Grid, __assign({ item: true, xs: 6, display: "flex" }, { children: _jsx(RememberMeCheckBoxChange, { label: "Remember Me", handleCheckboxChange: handleCheckBox, isPropChecked: remember }) })), _jsx(Grid, __assign({ item: true, xs: 6, display: "flex" }, { children: _jsx("div", __assign({ className: classes.forgotPassword }, { children: forgotPasswordLink() })) }))] })), state.landing.forgetPasswordPosition === "center" && !state.landing.showRememberMe &&
                                _jsx(Grid, __assign({ item: true, xs: true, className: classes.forgotPassword }, { children: forgotPasswordLink() })), isError && state.landing.alertPosition === "center" &&
                                _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(Alert, __assign({ severity: "error", className: classes.alertInput }, { children: loginErrorMessage ? loginErrorMessage : errorCodeMessage })) })), _jsx(Grid, __assign({ item: true, sx: { m: 1, margin: '8px 0', position: "relative", display: 'flex', justifyContent: 'center' } }, { children: _jsx(CommonSubmitButton, __assign({ disabled: isLoggingIn, loading: isLoggingIn, classes: classes.singInButton }, { children: state.landing.loginButtonText || "Sign In" })) }))] })), state.landing.forgetPasswordPosition !== "center" &&
                        _jsx(Grid, __assign({ item: true, xs: true, className: classes.forgotPassword }, { children: forgotPasswordLink({ variant: 'body2' }) })), state.landing.showPeriscope &&
                        _jsxs(Grid, __assign({ item: true, xs: true, className: classes.periscope }, { children: [_jsx("div", { children: _jsx("a", __assign({ href: state.landing.appleStorePeriscopeUrl }, { children: _jsx("img", { src: appStore, alt: "appstore", width: "153px" }) })) }), _jsx("div", __assign({ className: classes.periscopeGoogle }, { children: _jsx("a", __assign({ href: state.landing.googlePlayPeriscopeUrl }, { children: _jsx("img", { src: googlePlayStore, alt: "google play", width: "153px" }) })) }))] })), state.landing.notificationBannerHtml && state.landing.notificationBannerHtml.trim() !== "" &&
                        _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(BannerCard, __assign({ variant: "outlined" }, { children: _jsx(CardContent, { children: _jsx(Typography, { variant: "body2", dangerouslySetInnerHTML: { __html: state.landing.notificationBannerHtml } }) }) })) }))] }))] })));
};
export default SignIn;
