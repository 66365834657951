import { makeStyles } from '@mui/styles';
import React, {createContext, useContext} from "react";
import { styles } from './../../styles';

export const cssContext = createContext();


export const CssContextProvider = props => {
    const classes = styles ? makeStyles(theme => (styles(theme)))() : {};

    return (
        <cssContext.Provider value={{ classes }}>
            {props.children}
        </cssContext.Provider>
    );
};

export const useCssContext = () => useContext(cssContext);
