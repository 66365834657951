import red from '@mui/material/colors/red';
import {filledInputClasses} from "@mui/material";

const theme = {
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    margin: 0,
                    padding: 0,
                }
            }
        },
    },
    palette: {
        primary: {
            light: '#fc5336',
            main: '#fa6402',
            dark: '#333E48',
            contrastText: '#fff',
        },
        secondary: {
            light: '#e8f0fe',
            main: '#eeeeee',
            dark: '#333E48',
            contrastText: '#fff',
        },
        error: {
            main: red[400],
        },
        background: {
            default: '#fff',
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    background: "#333E48",
                },
                root: {
                    "&.Mui-disabled": {
                        background: '#A0A1A4',
                        color: '#ffffff'
                    }
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    color: '#333E48',
                    borderRadius: '8px',
                    borderColor: '#979797'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.description': {
                        fontWeight: 'bold !important',
                        paddingTop: '1rem',
                        lineHeight: '1.25'
                    }
                }

            }
        }
    }
};

export default theme;
