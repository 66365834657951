var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CommonLayout } from "../common/layout";
import { Alert, Grid, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import useSSOVerification from "./useSSOVerification";
import { Link as RouterLink } from "react-router-dom";
var LoadingImage = styled('img')({
    maxHeight: '50px',
    maxWidth: '100%',
    width: '100%'
});
var CommonSSOVerification = function () {
    var _a = useSSOVerification(), isError = _a.isError, loadingImgUrl = _a.loadingImgUrl, clientId = _a.clientId;
    return (_jsxs(CommonLayout, __assign({ isLanding: true }, { children: [isError &&
                _jsxs(_Fragment, { children: [_jsx(Grid, __assign({ item: true, xs: true, sx: { marginTop: "15px" } }, { children: _jsx(Alert, __assign({ severity: "error" }, { children: "Unable to load SSO information, please try again later" })) })), _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(Link, __assign({ component: RouterLink, to: "/login?clientId=".concat(clientId), variant: "body2", id: "goToLoginLink" }, { children: "Try to login again." })) }))] }), !isError &&
                _jsxs(_Fragment, { children: [_jsx(Grid, __assign({ item: true, xs: true, sx: { marginTop: "15px" } }, { children: _jsx(Typography, { children: "Finalizing your SSO login, please wait." }) })), _jsx(Grid, __assign({ item: true, xs: true, sx: { marginTop: "15px" } }, { children: _jsx(LoadingImage, { src: loadingImgUrl, alt: "Loading…" }) }))] })] })));
};
export default CommonSSOVerification;
