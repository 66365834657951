var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, Suspense, lazy, useEffect } from "react";
import { Button, Container, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSearchParams, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ClientConfigContext } from "../../../context/client-config";
import Footer from "../footer";
import theme from "../../../styles/MakaiPolice";
import { useCssContext } from "../../../context/css-context";
import { useTheme } from '@mui/material/styles';
var MarketingIFrame = lazy(function () { return import('./MarketingContentFrame'); });
import useMediaQuery from '@mui/material/useMediaQuery';
var Image = styled('img')({
    maxWidth: '100%'
});
var CommonLayout = function (_a) {
    var children = _a.children, _b = _a.isLanding, isLanding = _b === void 0 ? false : _b, _c = _a.classes, classes = _c === void 0 ? { loginFormGrid: '', appLogo: '', showMarketingImage: '' } : _c;
    var state = useContext(ClientConfigContext)[0];
    var searchParams = useSearchParams()[0];
    var navigate = useNavigate();
    var contextClasses = useCssContext().classes;
    var getLoginFormGrid = function () {
        if (state.landing.marketingGrid) {
            return 12 - state.landing.marketingGrid;
        }
        if (state.splitScreenLayout)
            return 6;
        return 4;
    };
    var themeVariables = useTheme();
    var isDesktopScreen = useMediaQuery(themeVariables.breakpoints.up('lg'));
    useEffect(function () {
        // Check if the page is being accessed from a on a small device/screen
        if (!isDesktopScreen) {
            // Get the meta viewport element by querySelector
            var metaViewport = document.querySelector('meta[name="viewport"]');
            // Set the Zoom scale
            if (metaViewport !== null) {
                metaViewport.setAttribute('content', 'width=device-width initial-scale=0.8');
            }
        }
    }, [isDesktopScreen]);
    return (_jsx(Container, __assign({ maxWidth: false, disableGutters: true, sx: {
            height: '100vh',
            width: '100vw'
        } }, { children: _jsxs(Grid, __assign({ container: true, direction: "row", spacing: 0, sx: { height: '100%' } }, { children: [state.splitScreenLayout && isDesktopScreen ?
                    (state.splitScreenLogoUrl &&
                        _jsx(Grid, __assign({ item: true, lg: 6, className: contextClasses.splitScreenLeftSection }, { children: _jsx(Image, { src: state.splitScreenLogoUrl, className: classes.appLogo, sx: {
                                    width: '100%',
                                    height: 'auto',
                                    maxWidth: '52%',
                                    maxHeight: '52%',
                                    objectFit: 'contain',
                                } }) }))) :
                    (isLanding && state.landing.showMarketingContent &&
                        _jsxs(Grid, __assign({ item: true, xs: state.landing.marketingGrid ? state.landing.marketingGrid : 8 }, { children: [state.landing.marketingContentType === "IFRAME" &&
                                    _jsx(Suspense, __assign({ fallback: _jsx("div", {}) }, { children: _jsx(MarketingIFrame, { src: state.landing.marketingContentUrl }) })), state.landing.marketingContentType === "IMAGE" &&
                                    _jsx(Image, { src: state.landing.marketingContentUrl, className: classes.showMarketingImage })] }))), _jsxs(Grid, __assign({ container: true, item: true, sx: { position: 'relative' }, md: state.splitScreenLayout ? 12 : ((isLanding && state.landing.showMarketingContent || state.splitScreenLayout) ? getLoginFormGrid() : 12), lg: (isLanding && state.landing.showMarketingContent || state.splitScreenLayout) ? getLoginFormGrid() : 12, justifyContent: "center", className: "".concat(classes.loginFormGrid, " ").concat(contextClasses.splitScreenRightSection) }, { children: [state.backButton && !isLanding &&
                            _jsx(Grid, __assign({ item: true, id: "backItem", sx: { color: theme.palette.primary.contrastText, height: "fit-content", position: 'absolute', top: -2, left: -2 } }, { children: _jsxs(Button, __assign({ onClick: function () { return navigate(-1); }, sx: { display: "flex" }, className: contextClasses.customTextField }, { children: [_jsx(ArrowBackIcon, { className: contextClasses.arrowBackIcon }), "BACK"] })) })), _jsxs(Grid, __assign({ container: !state.landing.evenSpaceForFooter, item: true, sx: state.landing.evenSpaceForFooter ? { display: 'flex', justifyContent: 'space-evenly' } : {}, xs: (state.splitScreenLayout ? 12 : (isLanding && state.landing.showMarketingContent ? 8 : 10)), sm: (state.splitScreenLayout ? 12 : (isLanding && state.landing.showMarketingContent ? 8 : 8)), md: (state.splitScreenLayout ? 12 : (isLanding && state.landing.showMarketingContent ? 8 : 4)), direction: "column", justifyContent: "space-around", alignContent: "center" }, { children: [_jsxs(Grid, __assign({ container: !state.landing.evenSpaceForFooter, item: true, direction: "column", justifyContent: "center", alignContent: "center", alignItems: "center" }, { children: [_jsx(Grid, __assign({ item: true, sx: { textAlign: "center" } }, { children: _jsx(Image, { src: state.logoUrl, className: classes.appLogo }) })), children] })), _jsx(Footer, { appVersion: state.appVersion, privacyLinkTarget: state.privacyLinkTarget, privacyLinkText: state.privacyLinkText, privacyLinkUrl: state.privacyLinkUrl, termsLinkTarget: state.termsLinkTarget, termsLinkText: state.termsLinkText, termsLinkUrl: state.termsLinkUrl, confirmForgotUrl: "/confirmForgot?clientId=".concat(searchParams.get('clientId')), isLanding: isLanding })] }))] }))] })) })));
};
export default CommonLayout;
