var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CompactEncrypt, importJWK } from "jose";
var JWE_ALG = "RSA-OAEP-256";
var JWE_ERROR_CODES = ["JWE_KEY_EXPIRED", "JWE_KEY_UNKNOWN", "JWE_CIPHER_FORMAT_INVALID", "JWE_MISSING_KEY", "JWE_CIPHER_INVALID"];
var USER_TOKEN_NAME = "X-Nspire-UserToken";
var allowedRetries = 3;
var currentRetries = 0;
var encryptAndPost = function (url, jsonBody, withAuth) {
    if (withAuth === void 0) { withAuth = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        var headers, publicJwkDto, jwk, jwe, response, responseJson, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    headers = {};
                    headers["Content-Type"] = "application/json";
                    if (withAuth) {
                        // @ts-ignore
                        headers[USER_TOKEN_NAME] = window.auth.clientConfig.jwt;
                    }
                    publicJwkDto = window.auth.clientConfig.initialJwe;
                    return [4 /*yield*/, importJWK(JSON.parse(publicJwkDto.jwk), JWE_ALG)];
                case 1:
                    jwk = _a.sent();
                    return [4 /*yield*/, new CompactEncrypt(new TextEncoder().encode(JSON.stringify(jsonBody)))
                            .setProtectedHeader({ alg: JWE_ALG, enc: 'A256GCM', kid: publicJwkDto.id })
                            .encrypt(jwk)];
                case 2:
                    jwe = _a.sent();
                    return [4 /*yield*/, postApi(headers, url, jwe)];
                case 3:
                    response = _a.sent();
                    if (!(!response.ok && currentRetries < allowedRetries)) return [3 /*break*/, 9];
                    _a.label = 4;
                case 4:
                    _a.trys.push([4, 8, , 9]);
                    return [4 /*yield*/, response.clone().json()];
                case 5:
                    responseJson = _a.sent();
                    if (!(responseJson.code && JWE_ERROR_CODES.indexOf(responseJson.code) > -1)) return [3 /*break*/, 7];
                    currentRetries++;
                    return [4 /*yield*/, refreshPublicJwk()];
                case 6:
                    _a.sent();
                    return [2 /*return*/, encryptAndPost(url, jsonBody)];
                case 7: return [3 /*break*/, 9];
                case 8:
                    e_1 = _a.sent();
                    console.error("Failed to parse error response as JSON, ".concat(e_1));
                    return [3 /*break*/, 9];
                case 9:
                    currentRetries = 0;
                    return [2 /*return*/, response];
            }
        });
    });
};
var postApi = function (headers, url, body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch(url, {
                    method: 'POST',
                    headers: headers,
                    body: body
                })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
var refreshPublicJwk = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, fetch('/rest/jwe/latest-jwk')];
            case 1:
                response = _b.sent();
                // @ts-ignore
                _a = window.auth.clientConfig;
                return [4 /*yield*/, response.json()];
            case 2:
                // @ts-ignore
                _a.initialJwe = _b.sent();
                return [2 /*return*/];
        }
    });
}); };
export { encryptAndPost };
