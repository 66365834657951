import React, { useReducer, createContext } from "react";
import mixpanelAnalytics from "../../analytics/mixpanelAnalytics";

export const ClientConfigContext = createContext();

const initialState = window.auth.clientConfig;

const reducer = (state, action) => {
    switch (action.type) {
        default:
            throw new Error();
    }
};

const mixpanelInit = (initialState) => {
    //initialise mixpanel once
    if(!(initialState?.analytics?.initialised) && initialState?.analytics?.mixpanel?.enabled && initialState?.analytics?.mixpanel?.clientToken){
        mixpanelAnalytics.init(initialState.analytics.mixpanel.clientToken)
        initialState.analytics.initialised = true;
    }
}

export const ClientConfigContextProvider = props => {
    mixpanelInit(initialState)
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <ClientConfigContext.Provider value={[state, dispatch]}>
            {props.children}
        </ClientConfigContext.Provider>
    );
};
