import { createTheme } from '@mui/material/styles';

import * as Styles from "./style";

const clientStyle = Styles[window.auth.clientConfig.style];

export const theme = createTheme({
    ...(clientStyle.default ? clientStyle.default : clientStyle)
});

export const styles = clientStyle.styles;
