var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, CircularProgress } from "@mui/material";
var CommonSubmitButton = function (_a) {
    var children = _a.children, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.loading, loading = _c === void 0 ? false : _c, _d = _a.classes, classes = _d === void 0 ? '' : _d;
    return (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ color: "primary", disabled: disabled, disableElevation: true, fullWidth: true, name: "formSubmit", type: "submit", variant: "contained", classes: { root: classes } }, { children: children })), loading &&
                _jsx(CircularProgress, { size: 24, sx: {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    } })] }));
};
export default CommonSubmitButton;
