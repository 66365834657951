var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { InputAdornment, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller } from "react-hook-form";
import { useCssContext } from "../../../context/css-context";
var ControlledTextField = function (_a) {
    var id = _a.id, autoComplete = _a.autoComplete, _b = _a.autoFocus, autoFocus = _b === void 0 ? false : _b, control = _a.control, _c = _a.defaultValue, defaultValue = _c === void 0 ? "" : _c, endAdornment = _a.endAdornment, _d = _a.isError, isError = _d === void 0 ? false : _d, _e = _a.helperText, helperText = _e === void 0 ? "" : _e, icon = _a.icon, _f = _a.label, label = _f === void 0 ? "" : _f, name = _a.name, rules = _a.rules, type = _a.type, _g = _a.variant, variant = _g === void 0 ? "standard" : _g, _h = _a.endAdornmentFlag, endAdornmentFlag = _h === void 0 ? true : _h;
    var inputProps = {};
    var classes = useCssContext().classes;
    if (icon !== undefined) {
        inputProps.startAdornment = (_jsx(InputAdornment, __assign({ position: "start" }, { children: _jsx(FontAwesomeIcon, { icon: icon }) })));
    }
    if (endAdornment !== undefined && endAdornmentFlag) {
        inputProps.endAdornment = (_jsx(InputAdornment, __assign({ position: "end" }, { children: endAdornment })));
    }
    return (_jsx(Controller, { control: control, defaultValue: defaultValue, name: name, rules: rules, render: function (_a) {
            var field = _a.field;
            return (_jsx(TextField, __assign({ id: id, autoComplete: autoComplete, autoFocus: autoFocus, error: isError, fullWidth: true, helperText: isError ? helperText : "", InputProps: __assign(__assign({}, inputProps), { classes: {
                        input: classes.customTextField
                    } }), InputLabelProps: { className: classes.customTextField }, label: label, margin: "normal", type: type, variant: variant }, field)));
        } }));
};
export default ControlledTextField;
