var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, AlertTitle, Grid, Link, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { ClientConfigContext } from "../../context/client-config";
import { encryptAndPost } from '../../services/encryptedApiService';
import { CommonLayout } from "../common/layout";
import { CommonSubmitButton } from "../common/submitButton";
import NewPasswordTextFields from "../common/newPasswordTextFields";
import { analyticsTrack, buildPropsWithStandardData } from "../../analytics";
import { AnalyticsEvent } from "../../analytics/analyticsEvents";
import NewPasswordRules from "../common/newPasswordRules";
import { clientIdUrl } from "../common/clientIdUrl";
var CommonAcceptForgotPasswordInvitation = function () {
    var state = useContext(ClientConfigContext)[0];
    var searchParams = useSearchParams()[0];
    var _a = useState({ baseError: false, authError: false }), errorState = _a[0], setErrorState = _a[1];
    var _b = useState(false), isSuccess = _b[0], setIsSuccess = _b[1];
    var _c = useState(false), isSubmitting = _c[0], setIsSubmitting = _c[1];
    var methods = useForm();
    var handleSubmit = methods.handleSubmit;
    var trackClick = function (extraInfo) {
        analyticsTrack(AnalyticsEvent.CLICK_ACCEPT_FORGOT_PASSWORD_INVITATION, state === null || state === void 0 ? void 0 : state.analytics, buildPropsWithStandardData(__assign(__assign({}, extraInfo), { viewPage: 'AcceptForgotPasswordInvitation', pageType: 'common' })));
    };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var dataToSubmit, trackReqInfo, postResult, resultJson, ex_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dataToSubmit = __assign({ clientId: searchParams.get('clientId'), challenge: state.acceptForgotPasswordInvitation.challenge, authCode: state.acceptForgotPasswordInvitation.authCode, secondaryAuthCode: state.acceptForgotPasswordInvitation.secondaryAuthCode }, data);
                    setErrorState(__assign(__assign({}, errorState), { baseError: false, authError: false }));
                    setIsSubmitting(true);
                    trackReqInfo = { username: data.username };
                    trackClick(__assign({}, trackReqInfo));
                    return [4 /*yield*/, encryptAndPost(clientIdUrl('/rest/acceptForgotPasswordInvitationRequest', state, searchParams), dataToSubmit)];
                case 1:
                    postResult = _a.sent();
                    setIsSubmitting(false);
                    if (!!postResult.ok) return [3 /*break*/, 6];
                    setErrorState(__assign(__assign({}, errorState), { baseError: true }));
                    trackClick(__assign(__assign({}, trackReqInfo), { result: 'FAILURE' }));
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, postResult.json()];
                case 3:
                    resultJson = _a.sent();
                    if (resultJson.code && resultJson.code === "ACCESS_DENIED") {
                        setErrorState(__assign(__assign({}, errorState), { baseError: true, authError: true }));
                    }
                    return [3 /*break*/, 5];
                case 4:
                    ex_1 = _a.sent();
                    console.error("Unable to decode response json, probably empty body: " + ex_1);
                    return [3 /*break*/, 5];
                case 5: return [3 /*break*/, 7];
                case 6:
                    trackClick(__assign(__assign({}, trackReqInfo), { result: 'SUCCESS' }));
                    setIsSuccess(true);
                    _a.label = 7;
                case 7: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(CommonLayout, __assign({ isLanding: true }, { children: [isSuccess &&
                _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsxs(Alert, __assign({ severity: "success" }, { children: [_jsx(AlertTitle, { children: "Your password has successfully been updated" }), _jsx(Link, __assign({ href: clientIdUrl('/auth/login', state, searchParams), variant: "body2" }, { children: "Return to Login" }))] })) })), !isSuccess &&
                _jsxs(_Fragment, { children: [errorState.baseError && !errorState.authError &&
                            _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(Alert, __assign({ severity: "error" }, { children: "Unable to change password. Please try again." })) })), errorState.baseError && errorState.authError &&
                            _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsxs(Alert, __assign({ severity: "error" }, { children: ["Unable to change password. One of the following errors occurred:", _jsxs("ul", { children: [_jsx("li", { children: "The pin is invalid" }), _jsx("li", { children: "The pin has already been used" }), _jsx("li", { children: "The pin has expired" })] }), "Please return to the ", _jsx(Link, __assign({ href: clientIdUrl('/auth/forgot', state, searchParams), variant: "body2", id: "returnToForgotPasswordLink" }, { children: "forgot password page" })), " and try again."] })) })), _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(FormProvider, __assign({}, methods, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(Grid, __assign({ item: true, xs: true, sx: { textAlign: "center", margin: "1.0rem 0" } }, { children: _jsxs(Typography, { children: [" Your access code has been sent to the email on file.", _jsx("br", {}), "If you have not received the email, please check your spam folder.", _jsx("br", {}), "You may resend the email if you still have not received it."] }) })), _jsx(NewPasswordRules, {}), _jsx(NewPasswordTextFields, { autoFocus: true }), _jsx(Grid, __assign({ item: true, sx: { m: 1, margin: '8px 0', position: "relative" } }, { children: _jsx(CommonSubmitButton, __assign({ disabled: isSubmitting, loading: isSubmitting }, { children: "Change Password" })) }))] })) })) }))] })] })));
};
export default CommonAcceptForgotPasswordInvitation;
