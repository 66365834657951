var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from "@mui/material";
import { ClientConfigContextProvider } from "./context/client-config";
import { CssContextProvider } from "./context/css-context";
import AppRoutes from "./routes";
import { theme } from './styles/index';
import { BrowserRouter as Router } from "react-router-dom";
export default (function () { return (_jsx(ClientConfigContextProvider, { children: _jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsxs(CssContextProvider, { children: [_jsx(CssBaseline, {}), _jsx(Router, __assign({ basename: "auth" }, { children: _jsx(AppRoutes, {}) }))] }) })) })); });
