//Return url with clientId parameter "/URL?clientId=..." if clientId is visible per settings
//or "/URL" if clientId is hidden per settings
export var clientIdUrl = function (baseUrl, state, searchParams) {
    if (state.hideClientId) {
        return baseUrl;
    }
    var queryOperator = '?';
    if (baseUrl.includes('?')) {
        queryOperator = '&';
    }
    return baseUrl + queryOperator + "clientId=".concat(searchParams.get('clientId'));
};
