import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ClientConfigContext } from "../../../context/client-config";
var RequireAuth = function (_a) {
    var children = _a.children;
    var state = useContext(ClientConfigContext)[0];
    var location = useLocation();
    if (!state.jwt || state.jwt == "") {
        return (_jsx(Navigate, { replace: true, to: "/login" + location.search }));
    }
    return children;
};
export default RequireAuth;
