var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { encryptAndPost } from "../../services/encryptedApiService";
import { CommonLayout } from "../common/layout";
import { CommonSubmitButton } from "../common/submitButton";
import ControlledTextField from "../common/controlledTextField";
import NewPasswordTextFields from "../common/newPasswordTextFields";
import { analyticsTrack, buildPropsWithStandardData } from "../../analytics";
import { AnalyticsEvent } from "../../analytics/analyticsEvents";
import { ClientConfigContext } from "../../context/client-config";
import NewPasswordRules from "../common/newPasswordRules";
import { clientIdUrl } from "../common/clientIdUrl";
import { useCssContext } from "../../context/css-context";
var CommonUserMigration = function () {
    var location = useLocation();
    var context = location.state || {};
    var navigate = useNavigate();
    var _a = useState(false), isSubmitting = _a[0], setIsSubmitting = _a[1];
    var _b = useState(false), isError = _b[0], setIsError = _b[1];
    var _c = useState(""), errorMessage = _c[0], setErrorMessage = _c[1];
    var searchParams = useSearchParams()[0];
    var classes = useCssContext().classes;
    var methods = useForm();
    var control = methods.control, errors = methods.formState.errors, handleSubmit = methods.handleSubmit;
    var state = useContext(ClientConfigContext)[0];
    var trackClick = function (extraInfo) {
        analyticsTrack(AnalyticsEvent.CLICK_USER_MIGRATION, state === null || state === void 0 ? void 0 : state.analytics, buildPropsWithStandardData(__assign(__assign({}, extraInfo), { viewPage: 'UserMigration', pageType: 'common' })));
    };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var migrationData, trackReqInfo, migrationResult, migrationResultJson, e_1, challengeState;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsError(false);
                    setIsSubmitting(true);
                    migrationData = __assign({ challengeContext: context.challengeContext }, data);
                    trackReqInfo = { username: data.username, email: data.email };
                    trackClick(__assign({}, trackReqInfo));
                    return [4 /*yield*/, encryptAndPost(clientIdUrl('/rest/migrateUserRequest', state, searchParams), migrationData)];
                case 1:
                    migrationResult = _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, migrationResult.json()];
                case 3:
                    migrationResultJson = _a.sent();
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _a.sent();
                    console.error("Failed to parse migrationResult as JSON, ".concat(e_1));
                    migrationResultJson = {};
                    return [3 /*break*/, 5];
                case 5:
                    if (!migrationResult.ok) {
                        setIsError(true);
                        setIsSubmitting(false);
                        if (migrationResultJson.message) {
                            setErrorMessage(migrationResultJson.message);
                        }
                        trackClick(__assign(__assign({}, trackReqInfo), { result: 'FAILURE' }));
                    }
                    else if (migrationResultJson.challenge === "EMAIL_VERIFICATION") {
                        challengeState = { challengeContext: "", email: "", token: "", username: context.username };
                        if (migrationResultJson && migrationResultJson.challengeContext) {
                            challengeState.challengeContext = migrationResultJson.challengeContext;
                        }
                        if (migrationResultJson && migrationResultJson.challengeData) {
                            challengeState.email = migrationResultJson.challengeData.email;
                        }
                        if (migrationResultJson && migrationResultJson.authResult) {
                            challengeState.token = migrationResultJson.authResult.token;
                        }
                        trackClick(__assign(__assign({}, trackReqInfo), { result: 'EMAIL_VERIFICATION' }));
                        navigate(clientIdUrl('/confirmEmail', state, searchParams), { state: challengeState });
                    }
                    else {
                        trackClick(__assign(__assign({}, trackReqInfo), { result: 'SUCCESS' }));
                        navigate(clientIdUrl("/landing?successMessage=".concat(encodeURIComponent('Your password has been updated.')), state, searchParams));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(CommonLayout, { children: _jsxs(_Fragment, { children: [isError && errorMessage === "" &&
                    _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(Alert, __assign({ className: classes.alertText, severity: "error" }, { children: "Unable to reset password. Please try again." })) })), isError && errorMessage !== "" &&
                    _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(Alert, __assign({ className: classes.alertText, severity: "error" }, { children: errorMessage })) })), _jsx(Grid, __assign({ md: state.splitScreenLayout && 6, sx: { display: "flex", alignItems: "center", flexDirection: "column" } }, { children: _jsx(FormProvider, __assign({}, methods, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(Grid, __assign({ item: true, xs: true, sx: { textAlign: "center", margin: "1.0rem 0" } }, { children: _jsx(Typography, __assign({ className: classes.subtitleText }, { children: "We have updated our security to better protect your data. Please update your password and email address to proceed. The email address will be used to reset your password if forgotten." })) })), _jsx(NewPasswordRules, {}), _jsx(NewPasswordTextFields, {}), _jsx(ControlledTextField, { autoComplete: "email", control: control, defaultValue: (context && context.email) ? context.email : "", isError: !!errors.email, helperText: errors.email ? errors.email.message : "", label: "Confirm or Update Email if needed", name: "email", rules: {
                                        required: _jsx(Typography, __assign({ className: classes.rulesStyleProps, variant: "caption" }, { children: "Required" })),
                                        email: true
                                    } }), _jsx(Grid, __assign({ item: true, sx: { m: 1, margin: '8px 0', position: "relative" } }, { children: _jsx(CommonSubmitButton, __assign({ disabled: isSubmitting, loading: isSubmitting, classes: classes.commonButton }, { children: "RESET PASSWORD" })) }))] })) })) }))] }) }));
};
export default CommonUserMigration;
