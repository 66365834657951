

export function buildSuccessUrl(successUrlContext, apiToken) {
    let successUrl = new URL(successUrlContext.successUrlTemplate);
    if(successUrlContext.apiTokenQueryParamKey) {
        successUrl.searchParams.append(successUrlContext.apiTokenQueryParamKey, apiToken)
    }

    if(!successUrl.hash && window.location.hash) {
        successUrl.hash = window.location.hash
    }
    return successUrl.toString();
}
