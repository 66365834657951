import red from '@mui/material/colors/red';

const theme = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 3000,
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    margin: 0,
                    padding: 0,
                }
            }
        },
    },
    palette: {
        primary: {
            light: '#fff',
            main: '#e8f0fe',
            dark: '#007aff',
            contrastText: '#fff',
        },
        secondary: {
            light: '#e8f0fe',
            main: 'rgba(255, 255, 255, 0.63)',
            dark: '#007aff',
            contrastText: '#fff',
        },
        error: {
            main: red[400],
        },
        background: {
            default: '#007aff',
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    background: "#ffffff",
                    color: "#007aff"
                },
                outlined: {
                    background: "transparent",
                    color: "#ffffff",
                    borderColor: "#ffffff"
                },
                root: {
                    "&.Mui-disabled": {
                        background: '#007aff',
                        color: '#ffffff'
                    },
                    "&:hover": {
                        color: '#ffffff'
                    }
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiInputBase:{
            styleOverrides: {
                root: {
                    borderBottom: '1px solid #ffffffbf',
                    color: "#fff",
                    '&:hover': {
                        borderBottom: '1px solid #ffffffbf'
                    },
                    '&:before': {
                        borderBottom: '1px solid #ffffffbf'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root:{
                    color: 'rgba(255, 255, 255, 0.75)'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: "#fff",
                    '&.description': {
                        fontWeight: 'bold !important',
                        paddingTop: '1rem',
                        lineHeight: '1.25'
                    }
                }

            }
        }
    },
};

export const styles = (theme) => {
    return ({
        splitScreenLeftSection: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        splitScreenRightSection: {
            backgroundColor: 'rgb(0,0,0, 0.15)',
            flexDirection: 'column'
        },
        subtitleText: {
            color: "#fff",
            fontSize: "1.2rem!important",
            [theme.breakpoints.up('lg')]: {
                fontSize: '0.9vw!important',
            }
        },
        confirmEmailText: {
            fontSize: "12.5px !important",
            color: "#fff",
            textWrap: "pretty",
            width: "fit-content"
        },
        singInButton: {
            marginTop: "50px !important",
            borderRadius: '2px !important',
            width: '80% !important',
            opacity: 0.6,
            fontSize: "1.2rem!important",
            [theme.breakpoints.up('lg')]: {
                fontSize: '0.8vw!important',
            }
        },
        setPasswordButton: {
            textTransform: 'none!important',
            marginTop: "50px !important",
            borderRadius: '8px !important',
            width: '80% !important',
            opacity: 0.6,
            fontSize: "1.2rem!important",
            [theme.breakpoints.up('lg')]: {
                fontSize: '0.8vw!important',
            }
        },
        requestAccessCodeButton: {
            textTransform: 'none!important',
            marginTop: "50px !important",
            opacity: 0.6,
            width: '80% !important',
            borderRadius: '8px !important',
            fontSize: "1.2rem!important",
            [theme.breakpoints.up('lg')]: {
                fontSize: '0.8vw!important',
            }
        },
        forgotPassword: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: "20px",
            paddingBottom: "20px",
            '& a': {
                textDecoration: 'none',
            },
            fontSize: "1.2rem!important",
            [theme.breakpoints.up('lg')]: {
                fontSize: '0.9vw!important',
            }
        },
        passwordRulesBox: {
            backgroundColor: 'rgb(0,0,0, 0.25)',
            borderRadius: '6px'
        },
        passwordRulesContent: {
            color: 'rgba(255, 255, 255, 0.75)',
            fontSize: "12px!important"
        },
        passwordRulesList: {
            marginTop: "0px",
            fontSize: "0.9rem!important",
            [theme.breakpoints.up('lg')]: {
                fontSize: '0.6vw!important',
            }
        },
        passwordRequirements: {
            fontSize: "12px!important"
        },
        hidePasswordRules: {
            display: 'none !important',
        },
        hideCopyright: {
            display: 'none !important',
        },
        hideFooterAccessCodeLink: {
            display: 'none !important'
        },
        resendLink: {
            display: 'none !important',
        },
        resendButton: {
            textTransform: 'none!important',
            margin: '0!important',
            fontSize: "1.2rem!important",
            [theme.breakpoints.up('lg')]: {
                fontSize: '0.8vw!important',
            }
        },
        privacyStmt: {
            display: 'flex',
            flexDirection: "column !important",
            alignItems: "center"
        },
        footerText: {
            fontSize: "1.2rem!important",
            [theme.breakpoints.up('lg')]: {
                fontSize: '0.8vw!important',
            },
            '& a': {
                fontSize: "1.2rem!important",
                [theme.breakpoints.up('lg')]: {
                    fontSize: '0.8vw!important',
                },
            },
        },
        titleText: {
        fontSize: "1.9rem!important",
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.6vw!important',
        }
    },
        alertText: {
        fontSize: "1.2rem!important",
            [theme.breakpoints.up('lg')]: {
            fontSize: '0.8vw!important',
        }
    },
    passwordRulesTitle: {
        fontSize: "1.0rem!important",
            [theme.breakpoints.up('lg')]: {
            fontSize: '0.7vw!important',
        }
    },
    commonButton: {
        fontSize: "1.3rem!important",
            [theme.breakpoints.up('lg')]: {
            fontSize: '0.9vw!important',
        }
    },
    customTextField: {
        fontSize: "1.2rem!important",
            [theme.breakpoints.up('xl')]: {
            fontSize: '0.9vw!important',
                height: '100px!important',
        }
    },
    arrowBackIcon: {
        fontSize: "1.4rem!important",
            [theme.breakpoints.up('lg')]: {
            fontSize: '1.1vw!important',
        }
    },
    rulesStyleProps: {
        color: 'rgb(239, 83, 80)!important',
            fontSize: "1.0rem!important",
            [theme.breakpoints.up('lg')]: {
            fontSize: '0.7vw!important',
        }
    },
    forgotPasswordMakai: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: "20px",
        paddingBottom: "20px",
        '& a': {
            textDecoration: 'none',
        },
        fontSize: "1.2rem!important",
        [theme.breakpoints.up('lg')]: {
            fontSize: '0.9vw!important',
        }
    },
    eyeIconStyle:{
        fontSize: "1.2rem!important",
        [theme.breakpoints.up('xl')]: {
            fontSize: '1.0vw!important',
        }
    }

});
}
export default theme;
