var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useContext, useState } from "react";
import { ClientConfigContext } from "../../context/client-config";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { analyticsTrack, buildPropsWithStandardData } from "../../analytics";
import { AnalyticsEvent } from "../../analytics/analyticsEvents";
import { encryptAndPost } from "../../services/encryptedApiService";
import { checkIsCodeExpired } from "../common/utils";
import { clientIdUrl } from "../common/clientIdUrl";
var useSetFirstPassword = function () {
    var state = useContext(ClientConfigContext)[0];
    var navigate = useNavigate();
    var _a = useState(false), isSubmitting = _a[0], setIsSubmitting = _a[1];
    var _b = useState(false), isError = _b[0], setIsError = _b[1];
    var _c = useState(false), isCodeExpired = _c[0], setIsCodeExpired = _c[1];
    var _d = useState(false), isSuccess = _d[0], setIsSuccess = _d[1];
    var searchParams = useSearchParams()[0];
    var _e = useState('ERROR_CODE'), errorCode = _e[0], setErrorCode = _e[1];
    var methods = useForm();
    var control = methods.control, errors = methods.formState.errors, handleSubmit = methods.handleSubmit;
    var redirectToForgot = function () {
        setIsSubmitting(true);
        encryptAndPost(clientIdUrl('/rest/approach-user-request', state, searchParams), { username: methods.getValues("username") })
            .finally(function () {
            setIsSubmitting(false);
        });
    };
    var trackClick = function (extraInfo) {
        analyticsTrack(AnalyticsEvent.CLICK_CONFIRM_FORGOT_PASSWORD, state === null || state === void 0 ? void 0 : state.analytics, buildPropsWithStandardData(__assign(__assign({}, extraInfo), { viewPage: 'ConfirmForgotPassword', pageType: 'common' })));
    };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var trackReqInfo, requestData, loginResult, isCodeExpiredValue, jsonResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsError(false);
                    setIsSubmitting(true);
                    setIsCodeExpired(false);
                    trackReqInfo = { username: data.username };
                    trackClick(__assign({}, trackReqInfo));
                    requestData = {
                        // @ts-ignore
                        username: data.username,
                        // @ts-ignore
                        authCode: data.authCode,
                        // @ts-ignore
                        password: data.newPassword
                    };
                    return [4 /*yield*/, encryptAndPost(clientIdUrl('/rest/confirm-first-password-request', state, searchParams), requestData)];
                case 1:
                    loginResult = _a.sent();
                    setIsSubmitting(false);
                    if (!loginResult.ok) return [3 /*break*/, 2];
                    setIsSuccess(true);
                    trackClick(__assign(__assign({}, trackReqInfo), { result: 'SUCCESS' }));
                    navigate(clientIdUrl("/landing?successMessage=".concat(encodeURIComponent('Your password has been set.')), state, searchParams));
                    return [3 /*break*/, 4];
                case 2:
                    isCodeExpiredValue = checkIsCodeExpired(loginResult);
                    setIsCodeExpired(isCodeExpiredValue);
                    setIsError(true);
                    return [4 /*yield*/, loginResult.json()];
                case 3:
                    jsonResponse = _a.sent();
                    setErrorCode(jsonResponse.code);
                    trackClick(__assign(__assign({}, trackReqInfo), { result: 'FAILURE' }));
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return {
        control: control,
        errors: errors,
        errorCode: errorCode,
        handleSubmit: handleSubmit,
        isError: isError,
        isCodeExpired: isCodeExpired,
        isSubmitting: isSubmitting,
        isSuccess: isSuccess,
        methods: methods,
        onSubmit: onSubmit,
        redirectToForgot: redirectToForgot,
        searchParams: searchParams,
        state: state
    };
};
export default useSetFirstPassword;
