var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormProvider } from "react-hook-form";
import { Alert, Grid, Link, Typography } from "@mui/material";
import NewPasswordRules from "../common/newPasswordRules";
import ControlledTextField from "../common/controlledTextField";
import NewPasswordTextFields from "../common/newPasswordTextFields";
import { CommonSubmitButton } from "../common/submitButton";
import { Link as RouterLink } from "react-router-dom";
import { CommonLayout } from "../common/layout";
import { useEffect } from "react";
import { CODE_EXPIRED_ERROR_MESSAGE, COMPROMISED_PASSWORD_ERROR_CODE, STRONGER_PASSWORD_ERROR_MESSAGE } from "../common/constant";
import { clientIdUrl } from "../common/clientIdUrl";
import { useCssContext } from "../../context/css-context";
import { CommonResendCodeButton } from "../common/resendCodeButton";
var CommonSetPassword = function (_a) {
    var control = _a.control, errors = _a.errors, errorCode = _a.errorCode, handleSubmit = _a.handleSubmit, isError = _a.isError, isCodeExpired = _a.isCodeExpired, isSubmitting = _a.isSubmitting, isSuccess = _a.isSuccess, methods = _a.methods, onSubmit = _a.onSubmit, redirectToForgot = _a.redirectToForgot, searchParams = _a.searchParams, state = _a.state, title = _a.title, description = _a.description, buttonText = _a.buttonText, newPasswordLabel = _a.newPasswordLabel, confirmNewPasswordLabel = _a.confirmNewPasswordLabel;
    var classes = useCssContext().classes;
    useEffect(function () {
        // Set the focus
        var usernameTextField = document.getElementById('usernameTextfieldId');
        if (usernameTextField !== null) {
            usernameTextField.focus();
        }
    }, []);
    function isPasswordCompromised(errorCode) {
        return errorCode === COMPROMISED_PASSWORD_ERROR_CODE;
    }
    return (_jsx(CommonLayout, { children: !isSuccess &&
            _jsxs(_Fragment, { children: [isError && isCodeExpired &&
                        _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(Alert, __assign({ className: classes.alertText, severity: "error" }, { children: CODE_EXPIRED_ERROR_MESSAGE })) })), isError && !isCodeExpired && isPasswordCompromised(errorCode) &&
                        _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(Alert, __assign({ severity: "error" }, { children: STRONGER_PASSWORD_ERROR_MESSAGE })) })), isError && !isCodeExpired && !isPasswordCompromised(errorCode) &&
                        _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(Alert, __assign({ className: classes.alertText, severity: "error" }, { children: "Unable to update password. Please try again." })) })), title &&
                        _jsx(Grid, __assign({ item: true, sx: { textAlign: "center" } }, { children: _jsx(Typography, __assign({ className: classes.titleText, variant: 'h5' }, { children: title })) })), _jsx(Grid, __assign({ sx: { textAlign: "center", margin: "1.0rem 0" } }, { children: _jsx("div", __assign({ className: classes.subtitleText }, { children: description ? _jsx(Typography, __assign({ className: classes.subtitleText, variant: "body2" }, { children: description }))
                                : _jsxs(Typography, __assign({ className: classes.subtitleText }, { children: [_jsx("b", { children: "Your access code has been sent to the email on file." }), _jsx("br", {}), _jsx("b", { children: "If you have not received the email, please check your spam folder." }), _jsx("br", {}), _jsx("b", { children: "You may resend the email if you still have not received it." })] })) })) })), _jsxs(Grid, __assign({ md: state.splitScreenLayout && 6, sx: { display: "flex", alignItems: "center", flexDirection: "column" } }, { children: [_jsx(FormProvider, __assign({}, methods, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(Grid, __assign({ item: true, className: classes.hidePasswordRules }, { children: _jsx(NewPasswordRules, {}) })), _jsx(ControlledTextField, { id: "usernameTextfieldId", autoComplete: "username", autoFocus: true, control: control, defaultValue: searchParams.get('username'), isError: !!errors.username, helperText: errors.username ? errors.username.message : "", label: "Username", name: "username", rules: { required: _jsx(Typography, __assign({ className: classes.rulesStyleProps, variant: "caption" }, { children: "Required" })) }, variant: state.textfieldVariant ? state.textfieldVariant : 'standard' }), _jsx(ControlledTextField, { autoComplete: "one-time-code", control: control, defaultValue: searchParams.get('code'), isError: !!errors.authCode, helperText: errors.authCode ? errors.authCode.message : "", label: "Access Code", name: "authCode", rules: { required: _jsx(Typography, __assign({ className: classes.rulesStyleProps, variant: "caption" }, { children: "Required" })) }, variant: state.textfieldVariant ? state.textfieldVariant : 'standard', endAdornment: (state.resendButtonOnTextfield &&
                                                _jsx(CommonResendCodeButton, __assign({ className: classes.resendButton, onClick: redirectToForgot }, { children: "Resend" }))), endAdornmentFlag: state.resendButtonOnTextfield ? true : false }), _jsx(Grid, __assign({ item: true, sx: { textAlign: "right" }, className: classes.resendLink }, { children: _jsx(Link, __assign({ onClick: redirectToForgot, variant: "body2", style: { cursor: 'pointer' } }, { children: "Resend Code" })) })), state.passwordRulesInsideForm &&
                                            _jsx(Grid, __assign({ item: true, className: classes.passwordRulesBox }, { children: _jsx(NewPasswordRules, {}) })), _jsx(Grid, __assign({ item: true }, { children: _jsx(NewPasswordTextFields, { variant: state.textfieldVariant ? state.textfieldVariant : 'standard', newPasswordLabel: newPasswordLabel, confirmNewPasswordLabel: confirmNewPasswordLabel }) })), _jsx(Grid, __assign({ item: true, sx: { m: 1, margin: '8px 0', position: "relative", display: 'flex', justifyContent: 'center' } }, { children: _jsx(CommonSubmitButton, __assign({ disabled: isSubmitting, loading: isSubmitting, classes: classes.setPasswordButton }, { children: buttonText ? buttonText : "Set Password" })) }))] })) })), !state.backButton &&
                                _jsx(Grid, __assign({ item: true, xs: true, sx: { marginTop: '10px', textAlign: 'center' } }, { children: _jsx(Link, __assign({ component: RouterLink, to: clientIdUrl('/login', state, searchParams), variant: "body2", id: "returnToForgotPasswordLink" }, { children: "CANCEL" })) }))] }))] }) }));
};
export default CommonSetPassword;
