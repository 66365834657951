var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { ClientConfigContext } from "../../context/client-config";
import { useCssContext } from "../../context/css-context";
var CustomerSupport = function () {
    var state = useContext(ClientConfigContext)[0];
    var classes = useCssContext().classes;
    return (_jsxs("div", __assign({ className: classes.supportHtml }, { children: [_jsx("div", { children: state.landing.customerSupportMessage }), _jsx("div", { children: _jsx("a", __assign({ href: "mailto:".concat(state.landing.customerSupportEmail) }, { children: state.landing.customerSupportEmail })) })] })));
};
export default CustomerSupport;
