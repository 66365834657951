import { useAuthFlow } from './authFlowService';
import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ClientConfigContext } from "../../context/client-config";
var useSSOLanding = function () {
    var _a;
    var state = useContext(ClientConfigContext)[0];
    var searchParams = useSearchParams()[0];
    var _b = useAuthFlow((_a = searchParams.get("clientId")) !== null && _a !== void 0 ? _a : ""), isError = _b.isError, authFlow = _b.authFlow;
    useEffect(function () {
        if (authFlow && (authFlow === null || authFlow === void 0 ? void 0 : authFlow.context)) {
            window.localStorage.setItem("idpContext", authFlow.context);
        }
    }, [authFlow]);
    var onContinueClick = function () {
        if (authFlow) {
            window.location.href = authFlow.uri;
        }
        else {
            console.error("Continue clicked before authFlow loaded.");
        }
    };
    return {
        isError: isError,
        authFlow: authFlow,
        state: state,
        loadingImgUrl: state.loadingUrl,
        onContinueClick: onContinueClick
    };
};
export default useSSOLanding;
