var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Grid, Link, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { encryptAndPost } from "../../services/encryptedApiService";
import { CommonLayout } from "../common/layout";
import { CommonSubmitButton } from "../common/submitButton";
import ControlledTextField from "../common/controlledTextField";
import { analyticsTrack, buildPropsWithStandardData } from "../../analytics";
import { AnalyticsEvent } from "../../analytics/analyticsEvents";
import { ClientConfigContext } from "../../context/client-config";
import { clientIdUrl } from "../common/clientIdUrl";
import { useCssContext } from "../../context/css-context";
var CommonForgotPassword = function () {
    var navigate = useNavigate();
    var _a = useState(false), isSubmitting = _a[0], setIsSubmitting = _a[1];
    var _b = useState(false), isError = _b[0], setIsError = _b[1];
    var searchParams = useSearchParams()[0];
    var _c = useForm(), control = _c.control, errors = _c.formState.errors, handleSubmit = _c.handleSubmit;
    var classes = useCssContext().classes;
    var state = useContext(ClientConfigContext)[0];
    var trackClick = function (extraInfo) {
        analyticsTrack(AnalyticsEvent.CLICK_FORGOT_PASSWORD, state === null || state === void 0 ? void 0 : state.analytics, buildPropsWithStandardData(__assign(__assign({}, extraInfo), { viewPage: 'ForgotPassword', pageType: 'common' })));
    };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var trackReqInfo, loginResult;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsError(false);
                    setIsSubmitting(true);
                    trackReqInfo = { username: data.term };
                    trackClick(__assign({}, trackReqInfo));
                    return [4 /*yield*/, encryptAndPost(clientIdUrl('/rest/forgotPasswordRequest', state, searchParams), data)];
                case 1:
                    loginResult = _a.sent();
                    setIsSubmitting(false);
                    if (loginResult.ok) {
                        navigate(clientIdUrl('/confirmForgot', state, searchParams));
                        trackClick(__assign(__assign({}, trackReqInfo), { result: 'SUCCESS' }));
                    }
                    else {
                        setIsError(true);
                        trackClick(__assign(__assign({}, trackReqInfo), { result: 'FAILURE' }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(CommonLayout, { children: [state.forgotPassword.title &&
                _jsx(Grid, __assign({ item: true, sx: { textAlign: "center", paddingBottom: "10px" } }, { children: _jsx(Typography, __assign({ className: classes.titleText, variant: 'h5' }, { children: "Recover Credentials" })) })), _jsx(Grid, __assign({ item: true, pb: 2, pt: 2 }, { children: state.forgotPassword.description ?
                    _jsx(Typography, __assign({ variant: "subtitle1", className: classes.subtitleText, gutterBottom: true, align: "center", component: "div" }, { children: state.forgotPassword.description })) :
                    _jsx(Typography, __assign({ variant: "subtitle1", className: classes.subtitleText, gutterBottom: true, align: "center", component: "div" }, { children: "A 6-digit access code will be sent to the email associated with your account" })) })), isError &&
                _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(Alert, __assign({ className: classes.alertText, severity: "error" }, { children: "Unable to send password reset. Please try again." })) })), _jsx(Grid, __assign({ md: state.splitScreenLayout && 5, sx: { width: "100%", display: "flex", alignItems: "center", flexDirection: "column" } }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit), style: { width: "100%" } }, { children: [_jsx(ControlledTextField, { autoComplete: "username", autoFocus: true, control: control, isError: !!errors.term, helperText: errors.term ? errors.term.message : "", label: "Username or Email", variant: state.textfieldVariant ? state.textfieldVariant : 'standard', name: "term", rules: { required: _jsx(Typography, __assign({ className: classes.rulesStyleProps, variant: "caption" }, { children: "Required" })) } }), _jsx(Grid, __assign({ item: true, sx: { m: 1, margin: '8px 0', position: "relative", display: 'flex', justifyContent: 'center' } }, { children: _jsx(CommonSubmitButton, __assign({ disabled: isSubmitting, loading: isSubmitting, classes: classes.requestAccessCodeButton }, { children: "Request Access Code" })) }))] })) })), !state.backButton &&
                _jsx(Grid, __assign({ item: true, xs: true, sx: { marginTop: '10px', textAlign: 'center' } }, { children: _jsx(Link, __assign({ component: RouterLink, to: clientIdUrl('/login', state, searchParams), variant: "body2", id: "returnToLoginLink" }, { children: "CANCEL" })) }))] }));
};
export default CommonForgotPassword;
