import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import * as AcceptForgotPasswordInvitations from "../../acceptForgotPasswordInvitation";
import * as ConfirmEmails from "../../confirmEmail";
import * as ConfirmForgotPasswords from "../../confirmForgotPassword";
import * as ForgotPasswords from "../../forgotPassword";
import * as Landings from "../../landing";
import * as ChangePasswords from "../../changePassword";
import * as UserMigrations from "../../userMigration";
import * as SetFirstPasswords from "../../setFirstPassword";
import * as SSOVerifications from "../../ssoVerification";
import * as SignIn from "../../signIn";
import { analyticsTrack, buildPropsWithStandardData } from "../../../analytics";
import { AnalyticsEvent } from "../../../analytics/analyticsEvents";
import * as CustomMfas from "../../customMfa";
var ViewSelector = function (_a) {
    var viewName = _a.viewName, viewPage = _a.viewPage, trackPage = _a.trackPage;
    var views = new Map();
    views.set(Page.ACCEPT_FORGOT_PASSWORD_INVITATION, AcceptForgotPasswordInvitations);
    views.set(Page.CONFIRM_EMAIL, ConfirmEmails);
    views.set(Page.CONFIRM_FORGOT_PASSWORD, ConfirmForgotPasswords);
    views.set(Page.FORGOT_PASSWORD, ForgotPasswords);
    views.set(Page.LANDING, Landings);
    views.set(Page.CHANGE_PASSWORD, ChangePasswords);
    views.set(Page.USER_MIGRATION, UserMigrations);
    views.set(Page.CUSTOM_MFA, CustomMfas);
    views.set(Page.SET_FIRST_PASSWORD, SetFirstPasswords);
    views.set(Page.SSO_VERIFICATION, SSOVerifications);
    views.set(Page.SIGN_IN, SignIn);
    var View = views.get(viewPage)[viewName];
    useEffect(function () {
        if (trackPage === null || trackPage === void 0 ? void 0 : trackPage.initialised) {
            var event_1;
            switch (viewPage) {
                case Page.ACCEPT_FORGOT_PASSWORD_INVITATION:
                    event_1 = AnalyticsEvent.SCREEN_ACCEPT_FORGOT_PASSWORD_INVITATION;
                    break;
                case Page.CONFIRM_EMAIL:
                    event_1 = AnalyticsEvent.SCREEN_CONFIRM_EMAIL;
                    break;
                case Page.CONFIRM_FORGOT_PASSWORD:
                    event_1 = AnalyticsEvent.SCREEN_CONFIRM_FORGOT_PASSWORD;
                    break;
                case Page.FORGOT_PASSWORD:
                    event_1 = AnalyticsEvent.SCREEN_FORGOT_PASSWORD;
                    break;
                case Page.LANDING:
                    event_1 = AnalyticsEvent.SCREEN_LANDING;
                    break;
                case Page.CHANGE_PASSWORD:
                    event_1 = AnalyticsEvent.SCREEN_CHANGE_PASSWORD;
                    break;
                case Page.USER_MIGRATION:
                    event_1 = AnalyticsEvent.SCREEN_USER_MIGRATION;
                    break;
                case Page.CUSTOM_MFA:
                    event_1 = AnalyticsEvent.SCREEN_CUSTOM_MFA;
                    break;
                case Page.SET_FIRST_PASSWORD:
                    event_1 = AnalyticsEvent.SCREEN_SET_FIRST_PASSWORD;
                    break;
                case Page.SSO_VERIFICATION:
                    event_1 = AnalyticsEvent.SCREEN_SSO_VERIFICATION;
                    break;
            }
            // @ts-ignore
            analyticsTrack(event_1, trackPage, buildPropsWithStandardData({ viewName: viewName }));
        }
    }, [viewPage]);
    return (_jsx(_Fragment, { children: !!View
            ? _jsx(View, {})
            : _jsxs("h1", { children: [viewName, " not found on page ", viewPage] }) }));
};
export default ViewSelector;
export var Page;
(function (Page) {
    Page["SIGN_IN"] = "SIGN_IN";
    Page["CONFIRM_EMAIL"] = "CONFIRM_EMAIL";
    Page["CONFIRM_FORGOT_PASSWORD"] = "CONFIRM_FORGOT_PASSWORD";
    Page["ACCEPT_FORGOT_PASSWORD_INVITATION"] = "ACCEPT_FORGOT_PASSWORD_INVITATION";
    Page["FORGOT_PASSWORD"] = "FORGOT_PASSWORD";
    Page["LANDING"] = "LANDING";
    Page["CHANGE_PASSWORD"] = "CHANGE_PASSWORD";
    Page["USER_MIGRATION"] = "USER_MIGRATION";
    Page["CUSTOM_MFA"] = "CUSTOM_MFA";
    Page["SET_FIRST_PASSWORD"] = "SET_FIRST_PASSWORD";
    Page["SSO_VERIFICATION"] = "SSO_VERIFICATION";
})(Page || (Page = {}));
