var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Grid, Typography } from "@mui/material";
import CommonLayout from "../common/layout/CommonLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
var MakaiLanding = function () {
    var _a, _b, _c, _d;
    var navigate = useNavigate();
    var searchParams = useSearchParams()[0];
    var themeVariables = useTheme();
    return (_jsx(CommonLayout, __assign({ isLanding: true }, { children: _jsxs(Grid, __assign({ container: true, spacing: 12, sx: { flexDirection: "column", textAlign: 'center', flexFlow: "column" }, alignItems: "center", justifyContent: "center" }, { children: [_jsxs(Grid, __assign({ item: true, md: 12 }, { children: [_jsx(Grid, __assign({ item: true }, { children: _jsx(Typography, __assign({ color: "primary", sx: (_a = {},
                                    _a[themeVariables.breakpoints.down('xl')] = { fontSize: '2rem' },
                                    _a[themeVariables.breakpoints.up('xl')] = { fontSize: '1.7vw' },
                                    _a) }, { children: "Welcome to LoJack." })) })), _jsx(Grid, __assign({ item: true }, { children: _jsx(Typography, __assign({ color: "primary", sx: (_b = {},
                                    _b[themeVariables.breakpoints.down('xl')] = { fontSize: '1.5rem' },
                                    _b[themeVariables.breakpoints.up('xl')] = { fontSize: '1.3vw' },
                                    _b) }, { children: " Let's get you set up." })) }))] })), _jsxs(Grid, __assign({ item: true, md: 12, sx: { width: '-webkit-fill-available',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column' } }, { children: [_jsx(Grid, __assign({ item: true, marginBottom: "20px", sx: { width: "100%" } }, { children: _jsx(Button, __assign({ sx: (_c = {},
                                    _c[themeVariables.breakpoints.down('md')] = { fontSize: '1.5rem' },
                                    _c[themeVariables.breakpoints.up('lg')] = { fontSize: '1vw' },
                                    _c), style: { textTransform: "none", padding: "1% 2.6%", whiteSpace: "nowrap" }, variant: 'contained', onClick: function () { return navigate("/signIn?clientId=".concat(searchParams.get('clientId'))); } }, { children: "I already have an account." })) })), _jsx(Grid, __assign({ item: true, sx: { width: "100%" } }, { children: _jsx(Button, __assign({ sx: (_d = {},
                                    _d[themeVariables.breakpoints.down('md')] = { fontSize: '1.5rem' },
                                    _d[themeVariables.breakpoints.up('lg')] = { fontSize: '1vw' },
                                    _d), style: { textTransform: "none", padding: "1% 2%", whiteSpace: "nowrap" }, variant: 'outlined', onClick: function () { return navigate("/setFirstPassword?clientId=".concat(searchParams.get('clientId'))); } }, { children: "I need to setup my account." })) }))] }))] })) })));
};
export default MakaiLanding;
