var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Grid, Link, Typography, InputAdornment } from "@mui/material";
import React, { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { encryptAndPost } from "../../services/encryptedApiService";
import { CommonLayout } from "../common/layout";
import { CommonSubmitButton } from "../common/submitButton";
import ControlledTextField from "../common/controlledTextField";
import NewPasswordTextFields from "../common/newPasswordTextFields";
import { ClientConfigContext } from "../../context/client-config";
import { analyticsTrack, buildPropsWithStandardData } from "../../analytics";
import { AnalyticsEvent } from "../../analytics/analyticsEvents";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import NewPasswordRules from "../common/newPasswordRules";
import { clientIdUrl } from "../common/clientIdUrl";
import { useCssContext } from "../../context/css-context";
var CommonChangePassword = function () {
    var navigate = useNavigate();
    var _a = useState(false), isSubmitting = _a[0], setIsSubmitting = _a[1];
    var _b = useState(false), isError = _b[0], setIsError = _b[1];
    var _c = useState(false), isSuccess = _c[0], setIsSuccess = _c[1];
    var searchParams = useSearchParams()[0];
    var state = useContext(ClientConfigContext)[0];
    var hasCancelLink = state.changePassword.hasCancelLink;
    var classes = useCssContext();
    var methods = useForm();
    var control = methods.control, errors = methods.formState.errors, handleSubmit = methods.handleSubmit;
    var trackClick = function (extraInfo) {
        analyticsTrack(AnalyticsEvent.CLICK_CHANGE_PASSWORD, state === null || state === void 0 ? void 0 : state.analytics, buildPropsWithStandardData(__assign(__assign({}, extraInfo), { viewPage: 'ChangePassword', pageType: 'common' })));
    };
    var _d = React.useState({
        showOldPassword: false,
    }), values = _d[0], setValues = _d[1];
    var handleClickShowPassword = function () {
        setValues(__assign(__assign({}, values), { showOldPassword: !values.showOldPassword }));
    };
    var handleMouseDownPassword = function (event) {
        event.preventDefault();
    };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var trackReqInfo, loginResult;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsError(false);
                    setIsSubmitting(true);
                    trackReqInfo = { username: data.username };
                    trackClick(__assign({}, trackReqInfo));
                    return [4 /*yield*/, encryptAndPost(clientIdUrl('/rest/userChangePasswordRequest', state, searchParams), data, true)];
                case 1:
                    loginResult = _a.sent();
                    setIsSubmitting(false);
                    if (loginResult.ok) {
                        setIsSuccess(true);
                        trackClick(__assign(__assign({}, trackReqInfo), { result: 'SUCCESS' }));
                        navigate(clientIdUrl("/landing?successMessage=".concat(encodeURIComponent('Your password has been updated.')), state, searchParams));
                    }
                    else {
                        trackClick(__assign(__assign({}, trackReqInfo), { result: 'FAILURE' }));
                        setIsError(true);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(CommonLayout, { children: !isSuccess &&
            _jsxs(_Fragment, { children: [isError &&
                        _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(Alert, __assign({ severity: "error" }, { children: "Unable to change password. Please try again." })) })), _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(FormProvider, __assign({}, methods, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(NewPasswordRules, {}), _jsx(ControlledTextField, { autoComplete: "current-password", autoFocus: true, control: control, isError: !!errors.oldPassword, helperText: errors.oldPassword ? errors.oldPassword.message : "", label: "Old Password", name: "oldPassword", rules: { required: _jsx(Typography, __assign({ className: classes.rulesStyleProps, variant: "caption" }, { children: "Required" })) }, type: values.showOldPassword ? "text" : "password", endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(IconButton, __assign({ onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword }, { children: values.showOldPassword ? _jsx(Visibility, { className: classes.eyeIconStyle }) : _jsx(VisibilityOff, { className: classes.eyeIconStyle }) })) })) }), _jsx(NewPasswordTextFields, {}), _jsx(Grid, __assign({ item: true, sx: { m: 1, margin: '8px 0', position: "relative" } }, { children: _jsx(CommonSubmitButton, __assign({ disabled: isSubmitting, loading: isSubmitting }, { children: "Change Password" })) })), hasCancelLink
                                        ?
                                            _jsx(Grid, __assign({ item: true, xs: true, sx: { marginTop: '10px', textAlign: 'center' } }, { children: _jsx(Link, __assign({ onClick: function () { return navigate(-1); }, variant: "body2", id: "cancelLink", style: { cursor: 'pointer' } }, { children: "CANCEL" })) }))
                                        :
                                            ''] })) })) }))] }) }));
};
export default CommonChangePassword;
