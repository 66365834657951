import mixpanelImpl from 'mixpanel-browser';
var mixpanelAnalytics = {
    init: function (token) {
        mixpanelImpl.init(token);
    },
    track: function (eventName, extraProperty) {
        var properties = extraProperty;
        mixpanelImpl.track(eventName, properties);
    }
};
export default mixpanelAnalytics;
