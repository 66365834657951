var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useCssContext } from "../../../context/css-context";
var NewPasswordRules = function () {
    var classes = useCssContext().classes;
    return (_jsx(_Fragment, { children: _jsx(Card, __assign({ variant: "outlined", sx: { fontSize: '12.5px' } }, { children: _jsxs(CardContent, __assign({ sx: { padding: '8px', paddingBottom: '0px !important' }, className: classes.passwordRulesContent }, { children: [_jsxs(Typography, __assign({ className: classes.passwordRulesTitle }, { children: [_jsx("b", { children: "Password Requirements" }), " (Minimum 8 Characters)"] })), _jsx(Typography, __assign({ className: classes.passwordRulesTitle, sx: { paddingLeft: "2px", fontSize: "12px" } }, { children: "At least 1 of each:" })), _jsxs(Grid, __assign({ container: true }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, md: 5 }, { children: _jsxs("ul", __assign({ style: { padding: '0px 12px' }, className: classes.passwordRulesList }, { children: [_jsx("li", { children: "Upper case letter" }), _jsx("li", { children: "Lower case letter" })] })) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 7 }, { children: _jsxs("ul", __assign({ style: { padding: '0px' }, className: classes.passwordRulesList }, { children: [_jsx("li", { children: "Number" }), _jsx("li", { children: "Special character(#?!@$%^&*-)" })] })) }))] }))] })) })) }));
};
export default NewPasswordRules;
