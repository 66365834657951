var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import mixpanelAnalytics from './mixpanelAnalytics';
import { AnalyticsEvent } from './analyticsEvents';
export function analyticsTrack(eventName, analytics, extraData) {
    var _a;
    // @ts-ignore
    if ((analytics === null || analytics === void 0 ? void 0 : analytics.initialised) && ((_a = analytics === null || analytics === void 0 ? void 0 : analytics.mixpanel) === null || _a === void 0 ? void 0 : _a.enabled)) {
        // @ts-ignore
        mixpanelAnalytics.track(eventName, __assign(__assign({}, extraData), { 'CLIENT_NAME': analytics.title }));
    }
}
export var buildPropsWithStandardData = function (extraData) {
    var analyticsProps = {};
    analyticsProps['APP_TYPE'] = AnalyticsEvent.APP_TYPE;
    if (extraData)
        analyticsProps = __assign(__assign({}, analyticsProps), extraData);
    return analyticsProps;
};
