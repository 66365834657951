var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Square from '@mui/icons-material/Square';
import { makeStyles } from '@mui/styles';
;
var useStyle = makeStyles(function () { return ({
    label: {
        marginLeft: '8px !important',
    },
    root: {
        paddingLeft: 9,
        paddingTop: 5,
    }
}); });
var RememberMeCheckBoxChange = function (props) {
    var _a = props.label, label = _a === void 0 ? '' : _a, handleCheckboxChange = props.handleCheckboxChange, isPropChecked = props.isPropChecked;
    var _b = React.useState(isPropChecked), checkbox = _b[0], setCheckbox = _b[1];
    var classes = useStyle();
    React.useEffect(function () {
        handleCheckboxChange(checkbox);
    }, [checkbox]);
    return (_jsx(FormGroup, __assign({ classes: {
            root: classes.root,
        } }, { children: _jsx(FormControlLabel, { classes: {
                label: classes.label,
            }, control: _jsx(Checkbox, { disableRipple: true, sx: {
                    color: '#d4d4d4',
                    borderRadius: '0',
                    border: 'solid 2px #fff',
                    padding: '7px',
                    height: 16,
                    width: 16,
                    '&:hover': {
                        color: '#fff',
                        border: 'solid 2px #d4d4d4',
                        padding: '7px',
                    }
                }, icon: _jsx(Square, { sx: { fontSize: 16 } }), checkedIcon: _jsx(Square, { sx: { color: '#343e48', fontSize: 16 } }), onChange: function () { return setCheckbox(!checkbox); }, checked: checkbox }), label: label }) })));
};
export default RememberMeCheckBoxChange;
