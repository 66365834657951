var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from "react-hook-form";
import ControlledTextField from "../controlledTextField";
import React, { useRef } from "react";
import { InputAdornment, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useCssContext } from "../../../context/css-context";
var NewPasswordTextFields = function (_a) {
    var _b = _a.autoFocus, autoFocus = _b === void 0 ? false : _b, icon = _a.icon, _c = _a.variant, variant = _c === void 0 ? "standard" : _c, newPasswordLabel = _a.newPasswordLabel, confirmNewPasswordLabel = _a.confirmNewPasswordLabel;
    var _d = useFormContext(), control = _d.control, errors = _d.formState.errors, watch = _d.watch;
    var classes = useCssContext().classes;
    var _e = React.useState({
        showNewPassword: false,
        showConfirmNewPassword: false,
    }), values = _e[0], setValues = _e[1];
    var handleClickShowNewPassword = function () {
        setValues(__assign(__assign({}, values), { showNewPassword: !values.showNewPassword }));
    };
    var handleClickShowConfirmNewPassword = function () {
        setValues(__assign(__assign({}, values), { showConfirmNewPassword: !values.showConfirmNewPassword }));
    };
    var handleMouseDownPassword = function (event) {
        event.preventDefault();
    };
    var password = useRef({});
    password.current = watch("newPassword", "");
    var newPasswordPattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;
    var newPasswordErrorMessage = "The password doesn't conform to all requirements.";
    return (_jsxs(_Fragment, { children: [_jsx(ControlledTextField, { autoFocus: autoFocus, autoComplete: "new-password", control: control, icon: icon, isError: !!errors.newPassword, helperText: errors.newPassword ? errors.newPassword.message : "", label: newPasswordLabel ? newPasswordLabel : "Create New Password", name: "newPassword", rules: {
                    required: _jsx(Typography, __assign({ className: classes.rulesStyleProps, variant: "caption" }, { children: "Required" })),
                    minLength: {
                        value: 8,
                        message: _jsx(Typography, __assign({ className: classes.rulesStyleProps, variant: "caption" }, { children: "Password must be at least 8 characters in length" }))
                    },
                    validate: {
                        containsNumber: function (value) { return /.*[0-9]/.test(value) || _jsx(Typography, __assign({ className: classes.rulesStyleProps, variant: "caption" }, { children: "Password must include at least one number" })); },
                        containsLower: function (value) { return /.*[a-z]/.test(value) || _jsx(Typography, __assign({ className: classes.rulesStyleProps, variant: "caption" }, { children: "Password must include at least one lower case letter" })); },
                        containsUpper: function (value) { return /.*[A-Z]/.test(value) || _jsx(Typography, __assign({ className: classes.rulesStyleProps, variant: "caption" }, { children: "Password must include at least one upper case letter" })); },
                        containsSpecial: function (value) { return /.*\W/.test(value) || _jsx(Typography, __assign({ className: classes.rulesStyleProps, variant: "caption" }, { children: "Password must include at least one special character(#?!@$%^&*-)" })); }
                    },
                    errorMessage: {
                        required: function () { return (_jsx(Typography, __assign({ className: classes.rulesStyleProps, variant: "caption" }, { children: "Required" }))); },
                    },
                }, type: values.showNewPassword ? "text" : "password", endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(IconButton, __assign({ onClick: handleClickShowNewPassword, onMouseDown: handleMouseDownPassword }, { children: values.showNewPassword ? _jsx(Visibility, { className: classes.eyeIconStyle }) : _jsx(VisibilityOff, { className: classes.eyeIconStyle }) })) })), variant: variant }), _jsx(ControlledTextField, { autoFocus: false, autoComplete: "new-password", control: control, icon: icon, isError: !!errors.confirmNewPassword, helperText: errors.confirmNewPassword ? errors.confirmNewPassword.message : "", label: confirmNewPasswordLabel ? confirmNewPasswordLabel : "Confirm New Password", name: "confirmNewPassword", rules: {
                    required: _jsx(Typography, __assign({ className: classes.rulesStyleProps, variant: "caption" }, { children: "Required" })),
                    validate: function (value) {
                        return password.current === value || _jsx(Typography, __assign({ className: classes.rulesStyleProps }, { children: "Passwords must match" }));
                    },
                    errorMessage: {
                        required: function () { return (_jsx(Typography, __assign({ className: classes.rulesStyleProps, variant: "caption" }, { children: "Required" }))); },
                    },
                }, type: values.showConfirmNewPassword ? "text" : "password", endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(IconButton, __assign({ onClick: handleClickShowConfirmNewPassword, onMouseDown: handleMouseDownPassword }, { children: values.showConfirmNewPassword ? _jsx(Visibility, { className: classes.customTextField }) : _jsx(VisibilityOff, { className: classes.customTextField }) })) })), variant: variant })] }));
};
export default NewPasswordTextFields;
